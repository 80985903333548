import { NextSeo } from 'next-seo';

import Container from '@components/global/Container';
import Bottom from '@components/pages/404/Bottom';
import Hero from '@components/pages/404/Hero';

import getGlobalProps from '@helpers/getGlobalProps';
import getPageLocales from '@helpers/getPageLocales';

import useTranslation from '@hooks/useTranslation';

export default function Custom404() {
    const { t } = useTranslation('errors/error404');
    return (
        <>
            <NextSeo
                title={t('meta_title')}
                description={t('meta_description')}
                noindex={true}
            />
            <main>
                <Hero container={<Container maxWidth={1220} />} />
                <Bottom container={<Container maxWidth={1220} />} />
            </main>
        </>
    );
}

export async function getStaticProps(context) {
    const globalProps = await getGlobalProps(context, '/404');
    return {
        props: globalProps,
        revalidate: 5 * 60,
    };
}

export const getStaticPaths = async () => {
    const locales = getPageLocales('/404');
    return {
        paths: locales.map((locale) => ({
            params: {
                locale,
            },
        })),
        fallback: false,
    };
};
